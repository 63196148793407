import { memo, useContext, useState } from "react";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbLayoutSidebarRightCollapse } from "react-icons/tb";
import { ColorModeContext, tokens } from "../../theme";
import { MdDarkMode, MdOutlineLightMode } from "react-icons/md";
import { makeStyles } from "@mui/styles";
import {
  ChargerIcon,
  DashboardIcon,
  IdlingIcon,
  LogoutIcon,
  ReportIcon,
  ReservationIcon,
  RestrictedIcon,
  TransactionsIcon,
} from "../../assets/icons/sidebar";
import { Logo, LogoDark } from "../../assets/icons";
import { ROUTES } from "../../routers";
import { Scrollbars } from "react-custom-scrollbars-2";
import usePermission from "../../hooks/usePermission";

export const ArrMenuSideBar = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    title: "Location",
    to: "/location",
    icon: <ChargerIcon />,
    role: "Location",
  },
  {
    title: "Charger point",
    to: "/charger-point",
    icon: <TransactionsIcon />,
    role: "ChargePoint",
  },
  {
    title: "Charge sessions",
    to: "/charge-sessions",
    icon: <ChargerIcon />,
    role: "ChargeSession",
  },
  {
    title: "Transactions",
    to: "/transactions",
    icon: <TransactionsIcon />,
    role: "Transaction",
  },
  {
    title: "Evse",
    to: ROUTES.EVSE,
    icon: <ChargerIcon />,
    role: "Evse",
  },
  {
    title: "Information",
    to: ROUTES.INFORMATION,
    icon: <ChargerIcon />,
    role: "Information",
  },
  {
    title: "Information Category",
    to: ROUTES.INFORMATION_CATEGORY,
    icon: <ChargerIcon />,
    role: "InformationCategory",
  },
  {
    title: "Idling penalty",
    to: "/idling-penalty",
    icon: <IdlingIcon />,
    role: "IdlingPenalty",
  },
  {
    title: "Reservation",
    to: "/reservation",
    icon: <ReservationIcon />,
    role: "Reservation",
  },
  {
    title: "User",
    to: "/user",
    icon: <RestrictedIcon />,
    role: "User",
  },
  {
    title: "Restricted User",
    to: "/user/restricted-user",
    icon: <RestrictedIcon />,
    role: "User",
  },
  {
    title: "Payouts",
    to: "/payouts",
    icon: <ChargerIcon />,
    role: "Payout",
  },
  {
    title: "Reports",
    to: "/reports",
    icon: <ReportIcon />,
    role: "Report",
  },
  {
    title: "Promo Code",
    to: "/promo-codes",
    icon: <ChargerIcon />,
    role: "PromoCode",
  },
  {
    title: "Promo Program",
    to: "/promo-programs",
    icon: <ChargerIcon />,
    role: "PromoProgram",
  },
  {
    title: "Subscription Plans",
    to: "/subscription-plans",
    icon: <ReportIcon />,
    role: "SubscriptionPlan",
  },
  {
    title: "Subscriptions",
    to: "/subscriptions",
    icon: <ReportIcon />,
    role: "Subscription",
  },
];

export const Item = memo(
  ({
    title,
    to,
    icon,
    location,
    isCollapsed,
    roleName,
  }: {
    title: string;
    to: string;
    icon: React.ReactNode;
    location: string;
    isCollapsed?: boolean;
    roleName?: string;
  }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isActive = location === to;
    const { role } = usePermission();

    return (role[roleName || ""] || []).includes("read") ? (
      <MenuItem
        style={{
          backgroundColor: isActive && isCollapsed ? "#c7c7c7" : "unset",
        }}
        component={<Link to={to} />}
        icon={icon}
      >
        <Typography
          color={isActive ? colors.primary["activeText"] : "#c7c7c7"}
          fontSize={"16px"}
          fontWeight={isActive ? "700" : "400"}
        >
          {title}
        </Typography>
      </MenuItem>
    ) : null;
  },
);

const useStyles = makeStyles<Record<string, any>>({
  btnToggleDarkMode: {
    outline: "none",
    border: "none",
    background: "inherit",
    cursor: "pointer",
    display: "flex",
    alignItem: "center",
    position: "absolute",
    top: "10px",
    right: "23px",
  },
});

const SidebarLayout = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const classes = useStyles() as any;
  const navigate = useNavigate();
  // const { data: profile } = useQuery("profile") as any;

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    navigate(ROUTES.LOGIN);
  };

  return (
    <Box
      position={"relative"}
      sx={{
        borderRadius: {
          xs: "0px",
          md: "20px",
        },
      }}
      overflow={"hidden"}
    >
      <Sidebar
        rootStyles={{
          height: "100%",
          paddingRight: "0px",
          [`.${sidebarClasses.container}`]: {
            backgroundColor: colors.primary["bg"],
            padding: "50px 20px 15px",
            display: !isCollapsed ? "flex" : "block",
          },
        }}
        collapsed={isCollapsed}
      >
        <Menu
          rootStyles={{
            height: "100%",
            width: "100%",
            [`ul`]: {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "15px",
              height: "100%",
            },
          }}
          menuItemStyles={{
            button: {
              padding: 0,
              borderRadius: "5px",
            },
          }}
        >
          <Box>
            <button
              onClick={colorMode.toggleColorMode}
              className={classes.btnToggleDarkMode}
            >
              {theme.palette.mode === "dark" ? (
                <MdOutlineLightMode color="#ffc107" size={30} />
              ) : (
                <MdDarkMode color="#ffc107" size={30} />
              )}
            </button>
            {!isCollapsed ? (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    borderRadius={"10px"}
                    overflow={"hidden"}
                    height={"48px"}
                    width={"53px"}
                  >
                    <img
                      height={"48px"}
                      width={"53px"}
                      src={theme.palette.mode === "light" ? Logo : LogoDark}
                      alt="Logo"
                    />
                  </Box>
                  <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                    <TbLayoutSidebarRightCollapse
                      color={colors.primary.activeText}
                    />
                  </IconButton>
                </Box>
              </>
            ) : (
              <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                <TbLayoutSidebarRightCollapse
                  color={colors.primary.activeText}
                />
              </IconButton>
            )}
          </Box>
          <Box flex={1}>
            <Scrollbars style={{ height: "100%" }} autoHide>
              {ArrMenuSideBar.map((item, index) => (
                <Item
                  key={index}
                  title={item.title}
                  to={item.to}
                  icon={item.icon}
                  location={location.pathname}
                  isCollapsed={isCollapsed}
                  roleName={item.role}
                />
              ))}
            </Scrollbars>
          </Box>
          <Box
            onClick={handleLogout}
            paddingLeft={!isCollapsed ? "15px" : "0px"}
            height={"48px"}
            display={"flex"}
            alignItems={"center"}
            gap={"20px"}
            style={{ cursor: "pointer" }}
          >
            <LogoutIcon color={colors.primary.activeText} />
            {!isCollapsed && (
              <Typography
                color={"#c7c7c7"}
                fontSize={"16px"}
                fontWeight={"700"}
              >
                Logout
              </Typography>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default SidebarLayout;
